<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      localVolume: 0,
      localTableType: 0,
      localCardType: 0,
      isSaved: false,
    }
  },
  computed: {
    ...mapGetters('config', ['volume', 'tableType', 'cardType', 'TABLE_TYPE_COUNT', 'CARD_TYPE_COUNT']),
    volumeValue: {
      get() {
        return this.volume;
      },
      set(value) {
        this.$store.commit('config/SET_VOLUME', value);
      }
    },
    tableTypeValue: {
      get() {
        return this.tableType;
      },
      set(value) {
        this.$store.commit('config/SET_TABLE_TYPE', value);
      }
    },
    cardTypeValue: {
      get() {
        return this.cardType;
      },
      set(value) {
        this.$store.commit('config/SET_CARD_TYPE', value);
      }
    }
  },
  methods: {
    save() {
      this.volumeValue = this.localVolume;
      this.tableTypeValue = this.localTableType;
      this.cardTypeValue = this.localCardType;
      this.isSaved = true;
    },
    beforeHide() {
      if (this.isSaved) {
        return;
      }

      this.localVolume = this.isSaved ? this.localVolume : this.volumeValue;
      this.localTableType = this.isSaved ? this.localTableType : this.tableTypeValue;
      this.localCardType = this.isSaved ? this.localCardType : this.cardTypeValue;
      this.isSaved = false;
    },
    reset() {
      this.localVolume = 50;
      this.localTableType = 1;
      this.localCardType = 1;
      this.isSaved = false;
    },
    init() {
      this.localVolume = this.volumeValue;
      this.localTableType = this.tableTypeValue;
      this.localCardType = this.cardTypeValue;
    },
    select(type, value) {
      switch (type) {
        case 'table':
          this.localTableType = value;
          break;
        case 'card':
          this.localCardType = value;
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.config.addEventListener('show.bs.modal', this.init, false)
      this.$refs.config.addEventListener('hidden.bs.modal', this.beforeHide, false)
    });
  },
}
</script>

<template>
  <!-- Modal -->
  <div ref="config" class="modal basic_modal fade" id="game_option" tabindex="-1" aria-labelledby="game_option"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">설정</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-4">
            <div class="tit_sub">사운드</div>
            <div class="d-flex align-items-center">
              <div><img src="@/assets/v2/svg/ico_volume.svg" alt=""></div>
              <div class="me-3" style="width: 70%;">
                <input
                  type="range"
                  class="form-range"
                  id="customRange1"
                  step="1"
                  min="1"
                  max="100"
                  v-model.number="localVolume"
                />
              </div>
              <div><span class="volume_value">{{ localVolume }}%</span></div>
            </div>
          </div>
          <div class="mb-4">
            <div class="tit_sub">테이블 선택</div>
            <div class="wrap_list">
              <template v-for="n in TABLE_TYPE_COUNT">
                <div
                  class="sellect_item"
                  :class="{ active: n === localTableType }"
                  :key="`table_type_0${n}`"
                  @click.prevent.stop="select('table', n)"
                >
                  <div class="thumb">
                    <div class="centered">
                      <img :src="require(`@/assets/v2/img/thumb_table0${n}.png`)" alt="테이블 색상">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div>
            <div class="tit_sub">카드모양</div>
            <div class="wrap_list">
              <template v-for="n in CARD_TYPE_COUNT">
                <div
                  class="sellect_item"
                  :class="{ active: n === localCardType }"
                  :key="`card_type_0${n}`"
                  @click.prevent.stop="select('card', n)"
                >
                  <div class="thumb">
                    <div class="centered">
                      <img :src="require(`@/assets/v2/img/thumb_card0${n}.png`)" alt="테이블 색상">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn_d btn_gray me-4" @click="reset">초기화
          </button>
          <button type="button" class="btn_d btn_blue" data-bs-dismiss="modal" @click.prevent.stop="save">저장</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sellect_item .thumb {
  position: relative;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.sellect_item .thumb:hover {
  transform-origin: center center;
  transform: scale(1.05);
  box-shadow: 2px 2px 4px 2px #2B3243;
}
</style>